import React, { useState, useRef, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import mixpanel from 'mixpanel-browser'
import { updateJob } from '../../api/meeting.api'
import LongJobDetails from './LongJobDetails'
import UpdateJobModel from './UpdateJobModel'
import CustomerHistoryModel from './CustomerHistoryModel'
import notificationSound from '../../assets/sounds/notification.mp3'
import useSound from 'use-sound'
import { openNotificationWithIcon, openNotificationWithIconWithCustomTimeDuration } from '../../utils'
import BusinessInfo from './BusinessInfo'
import * as UserApi from '../../api/user.api'
//import { JOB_CHARACTERS_ALLOWED } from '../../constants'
import TechnicainManageNotes from './TechnicainManageNotes'

const TechnicianSidebar = (props) => {
	const [customerHistoryVisible, setCustomerHistoryVisible] = useState(false)
	const [showSidebarLoader, setShowSidebarLoader] = useState(false)
	const [waitingForCustomer, setWaitingForCustomer] = useState(false)
	const [notesAvailable, setNotesAvailable] = useState(false)
	const [showUpdateJobModal, setShowUpdateJobModal] = useState(false)
	const [dataLoaded, setDataLoaded] = useState(false)
	const [alertMessageShow, setAlertMesasgeShow] = useState(false)
	const [declinedMessageShow, setDeclinedMessageShow] = useState(false)
	const [issuesList, setIssuesList] = useState([])
	const [editIssueIdx, setEditIssueIdx] = useState('')
	const [confirmedIssuesList, setConfirmedIssuesList] = useState([])
	const [notesSaveLoader, setNotesSaveLoader] = useState(false)
	const [showTransfterCallLoader, setShowTransfterCallLoader] = useState(false)
	const textAreaRef = useRef()
	const [play] = useSound(notificationSound)
	const [ownerHaveBusinessAccount, setOwnerHaveBusinessAccount] = useState(false)

	useEffect(() => {
		if (props.job && props.job.id) {
			if (!props.job.customerDeclinedNotes) {
				if (!props.job.technicianSubmitNotes) {
					if (props.job.allNotes && props.job.allNotes.trim().length > 0) {
						const issuesListArr = props.job.allNotes.split('|SEP|')
						setIssuesList(issuesListArr)
					}
				} else {
					if (props.job.allNotes && props.job.allNotes.trim().length > 0 && !props.job.customerConfirmedNotes) {
						setWaitingForCustomer(true)
					}
					if (props.job.allNotes && props.job.allNotes.trim().length > 0 && props.job.customerConfirmedNotes) {
						setNotesAvailable(true)
					}
					if (props.job.confirmedNotes && props.job.confirmedNotes.trim().length > 0 && props.job.customerConfirmedNotes) {
						const confirmedIssuesListArr = props.job.confirmedNotes.split('|SEP|')
						setConfirmedIssuesList(confirmedIssuesListArr)
					}
				}
			} else {
				setDeclinedMessageShow(true)
				setWaitingForCustomer(false)
				setNotesAvailable(false)
				if (props.job.allNotes && props.job.allNotes.trim().length > 0) {
					const issuesListArr = props.job.allNotes.split('|SEP|')
					setIssuesList(issuesListArr)
				}
			}
		}
	}, [props.job])

	useEffect(() => {
		if (props.socket) {
			props.socket.on('JobNotes:confirmed:transfer', async (socketData) => {
				if (socketData && socketData.jobId && props.job.id && socketData.jobId === props.job.id) {
					const confirmedIssuesListArr = [] //socketData.confirmedNotes ? socketData.confirmedNotes.split('|SEP|') : [];
					setConfirmedIssuesList(confirmedIssuesListArr)
					setWaitingForCustomer(false)
					setNotesAvailable(true)
				}
			})

			props.socket.on('JobNotes:declined:transfer', (socketData) => {
				if (socketData && socketData.jobId && props.job.id && socketData.jobId === props.job.id) {
					setWaitingForCustomer(false)
					setNotesAvailable(false)
					setDeclinedMessageShow(true)
				}
			})
			// Show Notification to Technician when customer's card authorization fails
			props.socket.on('notify-tech:customer-card-auth-fails', (data) => {
				console.log('notify-tech:customer-card-auth-fails')
				openNotificationWithIcon(
					'info',
					'Info',
					"Customer's card authorization fails, please ask him/her to add a valid card to continue otherwise meeting will be ended within 5 minutes.",
				)
				play()
			})
			// Show Notification to Technician when customer's subscription finished and card authorization fails
			props.socket.on('notify-tech:customer-subscription-over', (data) => {
				console.log('notify-tech:customer-subscription-over')
				openNotificationWithIcon(
					'info',
					'Info',
					"Customer's subscription is over, please ask him/her to add a valid card to continue otherwise meeting will be ended within 5 minutes.",
				)
				play()
			})
		}
	}, [props.socket])

	useEffect(() => {
		;(async () => {
			if (props.job?.customer?.user?.ownerId && props.job?.customer?.user?.ownerId !== null) {
				const ownerUserInfo = await UserApi.getUserByParam({ userId: props.job.customer.user.ownerId, cuId: props?.user?.id })
				if (ownerUserInfo && ownerUserInfo?.user && ownerUserInfo?.user?.isBusinessTypeAccount) {
					setOwnerHaveBusinessAccount(true)
				}
			}
		})()
	}, [props.job])

	/**
	 * Submit the note availabe in textarea
	 * @params = e (textarea element)
	 * @response : Will add note in issueList array
	 * @author : Karan
	 */
	const notesInputSubmit = () => {
		setAlertMesasgeShow(false)
		const issueText = textAreaRef.current.value
		const correctedIssueText = issueText.trim()
		if (correctedIssueText !== '') {
			const tempIssuesList = [...issuesList]
			if (editIssueIdx.toString() !== '') {
				// mixpanel code//
				mixpanel.identify(props.user.email)
				mixpanel.track(`Technician - Update Notes`, { JobId: props.job.id })
				// mixpanel code//
				tempIssuesList[editIssueIdx] = issueText
			} else {
				// mixpanel code//
				mixpanel.identify(props.user.email)
				mixpanel.track(`Technician - Add Notes`, { JobId: props.job.id })
				// mixpanel code//
				tempIssuesList.push(issueText)
			}
			setIssuesList(tempIssuesList)
			setEditIssueIdx('')
			textAreaRef.current.value = ''
		} else {
			setAlertMesasgeShow(true)
		}
	}

	/**
	 * Remove issue from arry
	 * @params = idx (array index)
	 * @response : Will remove note from issueList array
	 * @author : Karan
	 */
	const removeIssueFromList = (idx) => {
		const tempIssuesList = [...issuesList]
		tempIssuesList.splice(idx, 1)
		setIssuesList(tempIssuesList)
	}

	/**
	 * Edit note handler
	 * @params = idx (array index)
	 * @response : Will add selected value in textarea to edit
	 * @author : Karan
	 */
	const editIssueFromList = (idx) => {
		const tempIssuesList = [...issuesList]
		// setIssueValue(tempIssuesList[idx])
		textAreaRef.current.value = tempIssuesList[idx]
		setEditIssueIdx(idx)
	}

	/**
	 * Submit notes and send to customer
	 * @params = ''
	 * @response : Will add notes to database and send socket to customer
	 * @author : Karan
	 */
	const submitNotes = async () => {
		setNotesSaveLoader(true)
		setAlertMesasgeShow(false)
		if (issuesList.length > 0) {
			const jobId = props.job.id
			const jobNotes = issuesList.join('|SEP|')
			const dataToUpdate = {
				allNotes: jobNotes,
				customerDeclinedNotes: false,
				technicianSubmitNotes: true,
			}
			const updateStatus = await updateJob(jobId, {
				dataToUpdate: dataToUpdate,
				cuId: props?.user?.id,
			})

			if (updateStatus && updateStatus.success) {
				const data = {}
				data['jobId'] = jobId
				data['allNotes'] = jobNotes
				props.socket.emit('JobNotes:submitted', data)
				// mixpanel code
				mixpanel.identify(props.user.email)
				mixpanel.track('Technician - Submit the notes', { JobId: jobId })
				// mixpanel code
				window.notesSaveLoader = setTimeout(function () {
					setNotesSaveLoader(false)
					setWaitingForCustomer(true)
				}, 1500)
			} else {
				setAlertMesasgeShow(true)
				setNotesSaveLoader(false)
			}
		} else {
			// mixpanel.identify(user.email);
			// mixpanel.track('Technician - trying to submit the empty notes.',{'JobId':jobId});
			setAlertMesasgeShow(true)
			setNotesSaveLoader(false)
		}
	}

	/**
	 * Update job details
	 * @params = ''
	 * @response : Will open block with all job details to edit
	 * @author : Karan
	 */
	const updateJobDetails = async () => {
		setShowTransfterCallLoader(true)
		setShowUpdateJobModal(!showUpdateJobModal)
		// mixpanel code//
		mixpanel.identify(props.user.email)
		mixpanel.track(`Technician - Click on Update job details`, { JobId: props.job.id })
		// mixpanel code//
		if (!dataLoaded) {
			setShowSidebarLoader(true)
		} else {
			setShowTransfterCallLoader(false)
		}
	}

	return (
		<Row>
			<Col xs={12} className="mt-3 side-menu-bar px-3">
				<Row>
					{((props.job?.customer?.user?.isBusinessTypeAccount && props.job?.customer?.user?.ownerId === null) ||
						(props.job?.customer?.user?.ownerId && props.job?.customer?.user?.ownerId !== null && ownerHaveBusinessAccount)) && (
						<Col xs={12}>
							<BusinessInfo job={props.job} user={props.user} />
						</Col>
					)}

					<UpdateJobModel
						showUpdateJobModal={showUpdateJobModal}
						setShowUpdateJobModal={setShowUpdateJobModal}
						showTransfterCallLoader={showTransfterCallLoader}
						setShowTransfterCallLoader={setShowTransfterCallLoader}
						setDataLoaded={setDataLoaded}
						job={props.job}
						user={props.user}
						socket={props.socket}
						showHireExpertCheckbox={false}
						isTransferCall={false}
					/>

					<Col xs={12}>
						<Button className="btn app-btn app-btn-light-blue w-100 mt-3" onClick={updateJobDetails}>
							<span />
							Update Job Details
						</Button>
					</Col>

					<Col xs={12}>
						<div className="btn app-btn app-btn-light-blue w-100 mt-3">
							<TechnicainManageNotes job={props.job} user={props.user} />
						</div>
					</Col>

					<Col xs={12}>
						<CustomerHistoryModel
							setCustomerHistoryVisible={setCustomerHistoryVisible}
							customerHistoryVisible={customerHistoryVisible}
							job={props.job}
							user={props.user}
						/>
						<Button className="btn app-btn app-btn-light-blue w-100 mt-3" onClick={() => setCustomerHistoryVisible(true)}>
							<span />
							Customer History
						</Button>
					</Col>
					{/*<Loader height="100%" className={showSidebarLoader ? 'loader-outer' : 'd-none'} />
					{waitingForCustomer && (
						<Col xs={12}>
							<h5 className="font-weight-bold">Waiting for client to confirm the solution</h5>
							<Progress percent={70} showInfo={false} />
						</Col>
					)}
					{!waitingForCustomer && !notesAvailable && (
						<>
							<UpdateJobModel
								showUpdateJobModal={showUpdateJobModal}
								setShowUpdateJobModal={setShowUpdateJobModal}
								showTransfterCallLoader={showTransfterCallLoader}
								setShowTransfterCallLoader={setShowTransfterCallLoader}
								setDataLoaded={setDataLoaded}
								job={props.job}
								user={props.user}
								socket={props.socket}
								showHireExpertCheckbox={false}
								isTransferCall={false}
							/>
							<Col xs={12} className="mb-2">
								<h5 className="font-weight-bold">Confirm the issue with client</h5>
							</Col>
							<Col xs={12}>
								{alertMessageShow && (
									<Alert variant="danger" className="w-100">
										Please write the issue before click on add.
									</Alert>
								)}
								{declinedMessageShow && (
									<Alert variant="danger" className="w-100">
										Issues are declined by customer. Please review the issues.
									</Alert>
								)}
								<div className="mb-2">
									<textarea className="w-100 form-control" placeholder="Summarize the issue" ref={textAreaRef} />
									<Button
										className="btn app-btn app-btn-light-blue app-btn-small notes-add-btn w-100 mt-2"
										onClick={notesInputSubmit}
									>
										<span />
										{editIssueIdx.toString() !== '' ? <>Update</> : <>Add</>}
									</Button>
								</div>
								<div className="input-notes-text">
									{issuesList.length === 0 && (
										<div className="text-muted small-text">
											No issues summarized yet. <br />
											Describe the details of the client issue using the textbox above. <br />
											Select after each important detail to create a clear, listed outline of the issue
										</div>
									)}
									{issuesList.length > 0 && (
										<ul className="small-text p-0 m-0">
											{issuesList.map((i, idx) => {
												return (
													<li className="issue-list-item" key={idx}>
														<span className="issue-num">{idx + 1}.</span>
														<span className="issue-text">{i}</span>
														<span className="issue-action-btns text-center">
															<FontAwesomeIcon
																className="green-text mr-3"
																icon={faPencilAlt}
																title="Edit this issue"
																onClick={() => editIssueFromList(idx)}
															/>
															<FontAwesomeIcon
																className="red-text"
																icon={faTrash}
																onClick={() => removeIssueFromList(idx)}
																title="Remove this issue from list"
															/>
														</span>
													</li>
												)
											})}
										</ul>
									)}
								</div>
							</Col>

							<Col xs={12}>
								<Button
									className={
										(notesSaveLoader || issuesList.length === 0 ? 'disabled-btn' : '') +
										' btn app-btn w-100 mt-3 notes-submit-btn'
									}
									onClick={submitNotes}
									disabled={notesSaveLoader || issuesList.length === 0}
								>
									<span />
									{notesSaveLoader ? <Spin /> : <>Submit</>}
								</Button>
							</Col>
							<Col xs={12}>
								<Button className="btn app-btn app-btn-light-blue w-100 mt-3" onClick={updateJobDetails}>
									<span />
									Update Job Details
								</Button>
							</Col>

							<Col xs={12}>
								<CustomerHistoryModel
									setCustomerHistoryVisible={setCustomerHistoryVisible}
									customerHistoryVisible={customerHistoryVisible}
									job={props.job}
									user={props.user}
								/>
								<Button className="btn app-btn app-btn-light-blue w-100 mt-3" onClick={() => setCustomerHistoryVisible(true)}>
									<span />
									Customer History
								</Button>
							</Col>
						</>
					)}
					{notesAvailable && (
						<>
							<Col xs={12}>
								<h5 className="font-weight-bold">Issues confirmed by client :</h5>
							</Col>
							<Col xs={12} className="notes-outer">
								{confirmedIssuesList.length > 0 && (
									<ul className="small-text p-0 m-0">
										{confirmedIssuesList.map((i, idx) => {
											return (
												<li className="issue-list-item" key={idx}>
													<span className="issue-num">{idx + 1}.</span>
													<span className="issue-text">{i}</span>
												</li>
											)
										})}
									</ul>
								)}
							</Col>
						</>
					)}*/}

					{!props?.requiredParticipantJoined && props?.job?.meeting_start_time && (
						<LongJobDetails
							job={props.job}
							socket={props.socket}
							user={props.user}
							notesAvailable={notesAvailable}
							setShowEndmeetingButton={props.setShowEndmeetingButton}
							setIntialTimeVal={props.setIntialTimeVal}
							setShowTransferButton={props.setShowTransferButton}
							setEnableStartPauseTimerButton={props.setEnableStartPauseTimerButton}
							haveSubMin={props.haveSubMin}
						/>
					)}
				</Row>
			</Col>
		</Row>
	)
}

export default TechnicianSidebar
