import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { getParticipantDetails } from '../../../utils'
import { getIdFromJobId, openNotificationWithIcon, startTimer, getCookie } from '../../../utils'
import { updateJob, getJobInformation } from '../../../api/meeting.api'
import { isMobile } from 'react-device-detect'
import notifySound from '../../../assets/sounds/notification.mp3'
import mixpanel from 'mixpanel-browser'
import { JobTags, JITSI_URL_GETGEEKER } from '../../../constants'
import * as JobCycleApi from '../../../api/jobCycle.api'
import { notification } from 'antd'
import { JITSI_BASE_URL_GETGEEKER } from '../../../constants'
import { MicPermissionModal } from './MicPermissionModal'
import { AllowRemoteAccessModal } from './AllowRemoteAccessModal'
import MicrophoneBlocked from '../../../assets/images/mic-blocked.png'
import { checkCameraPermissions, checkMediaPermission } from '../../../utils/microphoneStatus'
import { AutomaticCallConnectModal } from './AutomaticCallConnectModal'
import MeetingOnboarding from '../../MeetingOnboarding/MeetingOnboarding'
import { StartPaidTimerAlert } from '../../StartPaidTimerAlert/StartPaidTimerAlert'
import { useJitsiMeet } from '../../../context/jitsiMeetContext'
import SharingYourScreenModal from '../../Common/SharingYourScreenModal/SharingYourScreenModal'

let api = null
const audio = new Audio(notifySound)

const JitsiContainer = (props) => {
	const [showLoader, setShowLoader] = useState(true)
	const [showStopButton, setshowStopButton] = useState(false)
	const [jitsiMeetId, setJitsiMeetId] = useState(null)
	const [oneapi, setOneApi] = useState({})
	const [isMicPermissionGiven, setIsMicPermissionGiven] = useState(true)
	const [micPermission, setMicPermission] = useState('notSet')
	const [openAutomaticCallModal, setAutomaticCallModal] = useState(false)
	const [disconnectedUserType, setDisconnectedUserType] = useState('')
	const [connectionStatus, setConnectionStatus] = useState({
		internet: { verified: false, message: 'Strong Connection', type: 'connection' },
		speaker: { verified: false, message: 'Your speakers are connected!', type: 'speaker' },
		microphone: { verified: false, message: 'Your microphone is connected!', type: 'microphone' },
	})
	const [openShareScreenModal, setOpenShareScreenModal] = useState(true);
	const { openBackDrop, setOpenBackDrop } = useJitsiMeet()
	let counter = 1;
	let startRecordingInterval
	// const [meetingStartedByCustomer,setMeetingStartedByCustomer] =  useState(false)

	useEffect(() => {
		if (!showLoader) {
			return () => {}
		}
		if (
			props.internetConnectionStatus[props.userType] &&
			Number(props.internetConnectionStatus[props.userType]) > 10 &&
			!connectionStatus.internet.verified
		) {
			setConnectionStatus((prev) => ({ ...prev, internet: { ...prev.internet, verified: true } }))
		}
		if (micPermission === 'permission-given' && !connectionStatus.microphone.verified) {
			setConnectionStatus((prev) => ({
				...prev,
				microphone: { ...prev.microphone, verified: true },
				speaker: { ...prev.speaker, verified: true },
			}))
		}
	}, [props.internetConnectionStatus, micPermission])

	// useEffect(() => {
	// 	if (props.meetingStartedByCustomer && props.userType == 'technician') {
	// openNotificationWithIcon('success', 'Meeting is resumed, billing starts now.');
	// 	}
	// }, [props.meetingStartedByCustomer])

	// props.socket.on('meeting-started-update-tech', async (data) => {
	// 	if(props.job.id === data.jobId){
	// 		setHasMeetingStarted(true)
	// 		setOpenBackDrop(false)
	// 		setMeetingStartedByCustomer(true)
	// 	}
	// })

	const [jitsiFakeLoader, setJitsiFakeLoader] = useState({
		firstLoading: 'loading',
		secondLoading: 'toLoad',
		thirdLoading: 'toLoad',
		fourthLoading: 'toLoad',
		fifthLoading: 'toLoad',
		sixthLoading: 'toLoad',
	})

	// These states are used to track conference joined if so then don't run fakeLoaderMessages function
	const [jitsiCountdownTimer, setJitsiCountDownTimer] = useState({ timer: 10, occurrence: 0, destroyCountDown: false })
	const [conferenceJoined, setConferenceJoined] = useState(false)

	let notificationMsgShow = false

	/**
	 *this function check whether both customer and tech joined from pc once "
	 * @author : Sahil Sharma
	 */
	const checkDesktopAvailability = (partispantsArr) => {
		try {
			const techName = props.job.technician.user.firstName.trim() + ' ' + props.job.technician.user.lastName.trim() + ' ' + '(t)'
			const custName = props.job.customer.user.firstName.trim() + ' ' + props.job.customer.user.lastName.trim() + ' ' + '(c)'
			let techJoined = partispantsArr.find((element) => element.displayName == techName)
			let custJoined = partispantsArr.find((element) => element.displayName == custName)
			console.log('checkDesktopAvailability :::::: 0',{techName,custName,techJoined,custJoined})

			if (techJoined !== undefined && custJoined !== undefined) {
				mixpanel.identify(props.user.email)
				mixpanel.track(`${props.userType} - joined from desktop`, { JobId: props.job.id, UserId: props?.user?.id })
				console.log('checkDesktopAvailability :::::: 1')
				return true
			} else {
				console.log('User not joined in Jitsi checkDesktopAvailability')
				return false
			}
		} catch (err) {
			console.log('Error in checkDesktopAvailability::::', err)
			return false
		}
	}

		/**
	 *this function check whether both customer or tech joined from phone call once "
	 * @author : Mritunjay Chaurasia
	 */
	 const checkPhoneAvailability = (partispantsArr) => {
		try {
			const techPhoneNumber = props.job.technician.profile.confirmId.phoneNumber
			const custPhoneNumber = props.job.customer.phoneNumber
			// let techJoined = partispantsArr.find((element) => element.displayName === techPhoneNumber) !== undefined
			let custJoined = partispantsArr.find((element) => element.displayName === custPhoneNumber) !== undefined
            console.log("checkPhoneAvailability>>>>>>>>>>>>>>>",custJoined)
			if (custJoined) {
				props.setPhoneStatusAvailability(true)
				mixpanel.identify(props.user.email)
				mixpanel.track(`${props.userType} - joined from Phone`, { JobId: props.job.id, UserId: props.user.id })
				return true
			} else {
				console.log('User not joined in Jitsi checkPhoneAvailability')
				props.setPhoneStatusAvailability(false)
				return false
			}
		} catch (err) {
			console.log('Error in checkPhoneAvailability::::', err)
			return false
		}
	}
	useEffect(() => {
		props.socket.on('fileupload-notification-frontend', async (data) => {
			
				console.log('fileupload-notification-frontend', data);
				if(!data.deleteFile){
					if (data.userType == 'customer') {
						
						if(props.userType == 'technician') {
							audio.play()
							console.log('fileupload-notification-frontend 1');
							
							openNotificationWithIcon('info', "Info", `📁 ${data.job.customer.user.firstName.replace(/^\w/, c => c.toUpperCase())} sent a file. You can view the uploaded files by clicking on the "Upload File" button.`);
	
						}
					}
					
					if (data.userType === 'technician') {
						if(props.userType === 'customer') {
							audio.play()
							openNotificationWithIcon('info', "Info", `📁 ${data.job.technician.user.firstName.replace(/^\w/, c => c.toUpperCase())} sent a file. You can view the uploaded files by clicking on the "Upload File" button.`);
	
							
						}
					}
				}
				
			})
			

	}, [props.socket]);
	
	// useEffect(() => {
	// 	props.socket.on('customer-zoho-open', (socketData) => {
			
	// 		if (props.userType === 'customer') {
	// 			let customerURL = socketData['representation']['customer_url'];
	// 			console.log('Attempting to open customer URL:', customerURL,socketData);
	// 			window.open(customerURL, '_blank');
	// 		}
		
	// 	})
	// }, [props.socket]);
	/**
	 * @description  : This useEffect will show toast to technician that customer have mic problem.
	 * @author : Jagroop
	 */
	useEffect(() => {
		if (props.userType == 'technician' && props.job.callStartType === 'ComputerAudio' && !props.phoneStatusAvailability) {
			props.socket.on('customer-mic-issue:send-back-to-tech', (data) => {
				if (data?.micError) {
					openNotificationWithIcon('error', "Can't Access Customer's Microphone", 'Please help customer regarding microphone permissions')
				}
			})
		}
	}, [])

	useEffect(() => {
		checkMicrophoneStatus()
		fakeLoaderMessages()
		if(isMobile){
			checkCameraStatus()
		}
	}, [])

	const fakeLoaderMessages = () => {
		setTimeout(() => {
			setJitsiFakeLoader((prev) => ({ ...prev, firstLoading: 'loaded', secondLoading: 'loading' }))
			setTimeout(() => {
				setJitsiFakeLoader((prev) => ({ ...prev, secondLoading: 'loaded', thirdLoading: 'loading' }))
				setTimeout(() => {
					setJitsiFakeLoader((prev) => ({ ...prev, thirdLoading: 'loaded', fourthLoading: 'loading' }))
					setTimeout(() => {
						setJitsiFakeLoader((prev) => ({ ...prev, fourthLoading: 'loaded', fifthLoading: 'loading' }))
						setTimeout(() => {
							setJitsiFakeLoader((prev) => ({ ...prev, fifthLoading: 'loaded', sixthLoading: 'loading' }))
							setJitsiCountDownTimer({ ...jitsiCountdownTimer, timer: 10, occurrence: jitsiCountdownTimer.occurrence + 1 })
						}, 3000)
					}, 3000)
				}, 3000)
			}, 3000)
		}, 3000)
	}

	useEffect(() => {
		const loadingState = jitsiFakeLoader.sixthLoading === 'loading'
		const occurrenceOfMsgs = jitsiCountdownTimer.occurrence <= 2
		const micConnected = micPermission == 'permission-given'
		const participantJoined = conferenceJoined
		console.log('jitsiCountdownTimer participantJoined', conferenceJoined, jitsiCountdownTimer)
		if (participantJoined) {
			return () => {}
		}
		if (loadingState && occurrenceOfMsgs && !participantJoined && micConnected) {
			if (jitsiCountdownTimer && jitsiCountdownTimer.timer >= 1) {
				let intervalId = setInterval(() => {
					setJitsiCountDownTimer({ ...jitsiCountdownTimer, timer: jitsiCountdownTimer.timer - 1 })
				}, 1000)

				return () => clearInterval(intervalId)
			}
			if (jitsiCountdownTimer && jitsiCountdownTimer.timer < 1) {
				initialiseJitsi(false)
				setJitsiFakeLoader({
					firstLoading: 'loading',
					secondLoading: 'toLoad',
					thirdLoading: 'toLoad',
					fourthLoading: 'toLoad',
					fifthLoading: 'toLoad',
					sixthLoading: 'toLoad',
				})
				fakeLoaderMessages()
			}
		}
		if (jitsiCountdownTimer.occurrence >= 2 && !participantJoined) {
			retryJitsi()
		}
	}, [jitsiCountdownTimer, micPermission])

	const checkMicrophoneStatus = async () => {
		const permissionState = await checkMediaPermission()
		console.log('check the microphoneStatus', permissionState)
		if (permissionState) {
			setMicPermission(permissionState)
		}
	}

	const checkCameraStatus = async()=>{
         const permissionState  = await checkCameraPermissions()
		 console.log("check camera permission", permissionState)
		 if(permissionState != 'permission-given'){
			 openNotificationWithIcon("warning", permissionState)
		 }
	}

	useEffect(() => {
		;(async () => {
			/**
			 * Set jitsiMeetID variable when Job and User data found
			 * @author : Karan
			 */
			if (props.job && props.job.id && props.user) {
				const jobId = await getIdFromJobId(props.job.id)
				// console.log('JitsiContainer:::setJitsiMeetId', jobId)
				setJitsiMeetId(jobId)
			}
		})()
	}, [props.job, props.user])

	useEffect(() => {
		/**
		 * Call initialise jitsi function when jitsiMeetId set
		 * @author : Karan
		 */
		if (jitsiMeetId) {
			if (props.job.callStartType === 'ComputerAudio') {
				console.log('JitsiContainer:::initialiseJitsi:::type 1')
				initialiseJitsi(false)
			} else {
				if (props.user && props.userType === 'customer') {
					console.log('JitsiContainer:::initialiseJitsi:::type 2')
					initialiseJitsi(true)
				} else {
					// console.log('JitsiContainer:::initialiseJitsi:::type 3')
					// props.job.technician_started_call = true

					if (props?.user && props.userType == 'technician') {
						console.log('JitsiContainer:::initialiseJitsi:::type 3')
						let start_call_technician = props.job.technician_started_call ? props.job.technician_started_call : false
						initialiseJitsi(start_call_technician)
					} else {
						console.log('JitsiContainer:::initialiseJitsi:::type 4')
						initialiseJitsi(false) //Enable computer audio for customer
					}
				}
			}
		}

		return () =>clearInterval(startRecordingInterval)
	}, [jitsiMeetId])

	const getScriptUrl = () => {
		return `${JITSI_URL_GETGEEKER}external_api.js`
	}

	/**
	 * Load scripts to initialise jitsi
	 * @params = ''
	 * @response : Will load JS scripts
	 * @author : Karan
	 */
	const loadJitsiScript = () => {
		console.log('JitsiContainer:::loadJitsiScript')
		let resolveLoadJitsiScriptPromise = null

		const loadJitsiScriptPromise = new Promise((resolve) => {
			resolveLoadJitsiScriptPromise = resolve
		})
		// console.log('JitsiContainer:::loadJitsiScriptPromise')
		const script1 = document.createElement('script')
		const script2 = document.createElement('script')
		const script3 = document.createElement('script')

		// scriptURL is selected from getScriptUrl function
		let scriptURL = getScriptUrl()
		console.log('JitsiContainer:::scriptURL', scriptURL)
		script1.src = scriptURL
		script2.src = 'https://code.jquery.com/jquery-3.5.1.min.js'
		script3.src = 'https://meet.jit.si/libs/lib-jitsi-meet.min.js'

		// console.log('JitsiContainer:::SetJitsiScriptElements')

		script1.async = true
		script2.async = true
		script3.async = true

		script1.onload = () => {
			// console.log('JitsiContainer:::LoadJitsiExternalApi scripts loading...')
			resolveLoadJitsiScriptPromise(true)
			// console.log('JitsiContainer:::LoadJitsiExternalApi loaded successfully...')
		}

		// console.log('JitsiContainer:::LoadJitsiExternalApi')

		document.body.appendChild(script1)
		document.body.appendChild(script2)
		document.body.appendChild(script3)

		// console.log('JitsiContainer:::AppendJitsiScriptElements and loadJitsiScriptPromise:', loadJitsiScriptPromise)

		return loadJitsiScriptPromise
	}

	/**
	 * @description : This function will provide configurations to Jitsi.
	 * @author : Jagroop
	 */
	const chooseConfigObjects = (computerAudioEnabled, startSilentMeeting) => {
		// Interface Config Overwrite
		const interFaceConfigOverwriteObject = {
			SHOW_PROMOTIONAL_CLOSE_PAGE: false,
			DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
		}
		// Config Overwrite
		const startVideoMuted = isMobile ? false : true
		let  toolbarButtons = ['microphone', 'fullscreen', 'fodeviceselection', 'profile', 'desktop']
		if(isMobile){
			toolbarButtons = [...toolbarButtons, "camera","toggle-camera"]
		}
		const configOverWriteObject = {
			startScreenSharing: false,
			startWithAudioMuted: computerAudioEnabled,
			startWithVideoMuted: startVideoMuted,
			startSilent: startSilentMeeting,
			notifications: [
				'recording.busy', // shown when recording service is busy
				'recording.failedToStart', // shown when recording fails to start
				'recording.unavailableTitle', // shown when recording service is not reachable
				'dialog.serviceUnavailable', // shown when server is not reachable
				'dialog.sessTerminated', // shown when there is a failed conference session
				'connection.CONNFAIL', // shown when the connection fails,
				'dialog.micNotSendingData', // shown when user's mic is not sending any audio
				'toolbar.noAudioSignalTitle', // shown when a broken mic is detected
				'toolbar.noisyAudioInputTitle', // shown when noise is detected for the current microphone],
				'notify.startSilentTitle', // shown when user joined with no audio
			],
			toolbarButtons: toolbarButtons,
			disableDeepLinking: true,
			cameraFacingMode: 'user'
		}

		return { interFaceConfigOverwriteObject, configOverWriteObject }
	}

	const selectMeetingMedium = () => {
		return JITSI_BASE_URL_GETGEEKER
	}

	/**
	 * Initialise jitsi to start the meeting
	 * @params = computerAudioEnabled=True/False
	 * @response : Will initialise jitsi
	 * @author : Karan
	 */
	const initialiseJitsi = async (computerAudioEnabled) => {
		console.log('JitsiContainer:::initialiseJitsi')
		let displayNametype
		try {
			let startSilentMeeting = computerAudioEnabled ? true : false
			let customerCalledHimself = false
			let callDone = true
			// const wantToUseComputerAudio =
			// 	window.localStorage.getItem('usingComputerAudio') && window.localStorage.getItem('usingComputerAudio') == 'true' ? true : false

			if (!window.JitsiMeetExternalAPI) {
				// console.log('JitsiContainer:::Going to call loadJitsiScript to load the scripts file...')
				await loadJitsiScript()
				console.log('JitsiContainer:::loadJitsiScriptLoaded Successfully')
			} else {
				console.log('JitsiContainer:::already have jitsi.')
			}

			console.log('JitsiContainer:::Ready to start the jitsi...')

			if (window.localStorage.getItem('customerCallingHimSelf') !== null && window.localStorage.getItem('customerCallingHimSelf') === 'true') {
				startSilentMeeting = true
				customerCalledHimself = true
				// setAudioIcon(true)
			}
			if (props.user && props.userType === 'customer' && props.job.technician_started_call) {
				startSilentMeeting = true
				customerCalledHimself = false
				computerAudioEnabled = false
			}
			if (props.userType == 'technician' && props.job.technician_started_call) {
				startSilentMeeting = false
				computerAudioEnabled = false
				customerCalledHimself = true
			}

			// This condition will only works when  technicina want to unmute customer
			const unMuteCusomter = window.sessionStorage.getItem('unMuteCusomter')
			if (props.user && props.userType === 'customer' && unMuteCusomter && unMuteCusomter == 'yes') {
				startSilentMeeting = false
				customerCalledHimself = false
				computerAudioEnabled = false
			}

			// When Customer press button Computer Audio then this will set on LocalStorage .So this local storage is used to start/restart meeting with Enabled microphone if available
			const usingComputerAudio =
				window.localStorage.getItem('usingComputerAudio') && window.localStorage.getItem('usingComputerAudio') == 'true' ? true : false
			const customerCallingHimSelf =
				window.localStorage.getItem('customerCallingHimSelf') !== null && window.localStorage.getItem('customerCallingHimSelf') === 'true'
					? true
					: false

			if (props.user && props.userType === 'customer' && usingComputerAudio && !customerCallingHimSelf) {
				startSilentMeeting = false
				customerCalledHimself = false
				computerAudioEnabled = false
			}
			if (props.userType == 'technician') {
				displayNametype = '(t)'
			} else {
				displayNametype = '(c)'
			}
			// console.log('JitsiContainer:::InvokingJitsiMeetExternalAPI')
			const configObjects = chooseConfigObjects(computerAudioEnabled, startSilentMeeting)
			let interFaceConfigOverwriteObject = configObjects.interFaceConfigOverwriteObject
			let configOverWriteObject = configObjects.configOverWriteObject
			const MEETING_MEDIUM = selectMeetingMedium()
			const PARENT_NODE = props.jitsiContainerId
			// console.log('Verification log that will check correct data is send to the Jitsi API', { configObjects, MEETING_MEDIUM, PARENT_NODE })
			api = new window.JitsiMeetExternalAPI(MEETING_MEDIUM, {
				interfaceConfigOverwrite: interFaceConfigOverwriteObject,
				configOverwrite: configOverWriteObject,
				parentNode: document.getElementById(PARENT_NODE),
				roomName: jitsiMeetId,
				jwt: '',
				displayName: 'Geeker Meeting',
				userInfo: {
					displayName: props.user?.firstName.trim() + ' ' + props.user?.lastName.trim() + ' ' + displayNametype,
					email: props?.user?.email,
					id: 1,
					userId: props?.user?.id,
				},
			})

			// console.log('JitsiContainer::: AfterInvokingJitsiMeetExternalAPI setJitsiApi:::', api)
			setOneApi(api)
			props.setJitsiApi(api)
			// This code is commented by Jagroop as it with this customer call started with ComputerAudio and not with Phone
			// if (props.user && props.userType === 'technician' && props.job.technician_started_call && props.job.technician_started_call == true) {
			// 	updateJob(props.job.id, { dataToUpdate: { callStartType: 'ComputerAudio' }, cuId:props.user.id })
			// }
			if (props.user && props.userType === 'customer') {
				updateJob(props.job.id, {
					dataToUpdate: { technician_started_call: false },
					cuId: props?.user?.id,
				})
			}

			if (props.job.callStartType === 'ComputerAudio' && !props.phoneStatusAvailability) {
				api.on('micError', (type, message) => {
					console.log('micError>>>>>>>>>>>>>>>>>', type, message)
					//mixpanel code
					mixpanel.identify(props.user.email)
					mixpanel.track(`${props.userType} - micError`, { JobId: props.job.id, message: message })
					//mixpanel code
					// This socket helps to send popup to technician that my(customer) mic is not working
					if (props.userType === 'customer') {
						const data = {
							JobId: props.job.id,
							micError: true,
						}
						props.socket.emit('customer-mic-issue', data)
					}
					if (micPermission != 'permission-given') {
						const notificationInfo = {
							key: 'MicPermissionIssue',
							duration: 4,
							message: 'Microphone not available',
							description: (
								<>
									Geeker requires access to your microphone.Click to the Microphone blocked icon{' '}
									<span>
										<img src={MicrophoneBlocked} className="img-fluid microphone-image-style" />
									</span>{' '}
									in your browser's address bar.
								</>
							),
						}
						notification.warn(notificationInfo)
					} else {
						openNotificationWithIcon(
							'info',
							'Info',
							'Check your system settings to make sure that a microphone is available. If not, plug one in. You might then need to restart your browser.',
						)
					}
				})
			}
			api.on('browserSupport', (data) => {
				console.log('JitsiContainer::: IsCurrentBrowserSupported ::: ', data.supported)
			})
			api.on('log', (logLevel, args) => {
				console.log('JitsiContainer::: logLevel :::: ', logLevel)
				console.log('JitsiContainer::: args ::: ', args)
			})
			api.on('toolbarButtonClicked', (key, preventExecution) => {
				console.log('JitsiContainer::: toolbarButtonClicked ::::::: ')
				api.executeCommand('toggleShareScreen')
				mixpanel.identify(props.user.email)
				mixpanel.track(`${props.userType} - toolbarButtonClicked `, { JobId: props.job.id, customerClickedOn: key })
			})
			api.on('errorOccurred', (details, message, name, type, isFatal) => {
				console.log('JitsiContainer::: error occured in jitsi api :::::: please check', {
					details: details,
					message: message,
					name: name,
					type: type,
					isFatal: isFatal,
				})
			})
			api.on('videoConferenceLeft', (info) => {
				console.log('JitsiContainer::: videoConferenceLeft :', info)
				//mixpanel code
				mixpanel.identify(props.user.email)
				mixpanel.track(`${props.userType} - videoconferenceleft`, { JobId: props.job.id })
				//mixpanel code
				stopShareScreen()
			})

			if (computerAudioEnabled) {
				api.setAudioOutputDevice(null, null)
			}

			api.on('videoConferenceJoined', (info) => {
				console.log('JitsiContainer:::videoConferenceJoined :', info)
				setConferenceJoined(true)
				if (props?.job?.meeting_start_time) {
					// console.log('JitsiContainer:::videoConferenceJoined  1:', info)
					notification.destroy()
					notification.success({ key: 'videoConferenceJoined', duration: 4, message: "You've been reconnected! Enjoy your meeting" })
					props.socket.emit('reconnection-alert', { JobId: props.job.id, reconnected: true, userType: props.userType })
					const reconnected = sessionStorage.getItem('reconnected') === 'true'

					if (reconnected) {
						console.log('JitsiContainer:::videoConferenceJoined  1:', info)
						notification.destroy()
						notification.success({ key: 'videoConferenceJoined', duration: 4, message: "You've been reconnected! Enjoy your meeting" })
						props.socket.emit('reconnection-alert', { JobId: props.job.id, reconnected: true, userType: props.userType })
					} else {
						sessionStorage.setItem('reconnected', 'true')
					}
				}
				if (!props?.job?.meeting_start_time) {
					checkParticipantInfo(10000)
				}
				//socket.emit("DisableLoader")
				//mixpanel code
				mixpanel.identify(props.user.email)
				mixpanel.track(`${props.userType}- Jitsi session started'`, { JobId: props.job.id })
				setShowLoader(false)
				//mixpanel code
			})

			api.on('participantLeft', async (info) => {
				if (props?.job?.meeting_start_time && !props.jitsiSessionEnd) {
					console.log('JitsiContainer:::participantLeft :', info)
					checkParticipantInfo(45000)

					let joinedParticipentArr = []
					try {
						joinedParticipentArr = await api.getRoomsInfo()
					} catch (error) {
						joinedParticipentArr = api.getParticipantsInfo()
					}
					if (joinedParticipentArr.length <= 0) {
						joinedParticipentArr = api.getParticipantsInfo()
						console.log('JitsiContainer::: participantInfo inside participantLeft condition', joinedParticipentArr)
					}
					checkPhoneAvailability(joinedParticipentArr)
				}
				//socket.emit("DisableLoader")
				//mixpanel code
				mixpanel.identify(props.user.email)
				mixpanel.track(`${props.userType}- Jitsi participant left'`, { JobId: props.job.id })
				setShowLoader(false)
				//mixpanel code
			})

			api.on('screenSharingStatusChanged', (screen_data) => {
				console.log('JitsiContainer:::screenSharingStatusChanged :', screen_data)
				props.setIsScreenShared(screen_data && screen_data.on ? screen_data.on : false)
				if (screen_data['on'] === true) {
					setshowStopButton(true)
				} else {
					setshowStopButton(false)
				}

				mixpanel.identify(props.user.email)
				mixpanel.track(`${props.userType} - screenSharingStatusChanged `, { JobId: props.job.id, screenshareStatus: screen_data['on'] })
			})

			api.on('incomingMessage', (info) => {
				console.log('JitsiContainer:::incomingMessage :', info)
				if (!notificationMsgShow) {
					audio.play() //Moved by karan inside if condition
					notificationMsgShow = true
					const msgFrom = props.userType === 'technician' ? 'customer' : 'technician'
					openNotificationWithIcon('info', 'Info', `New message from ${msgFrom}`)
					setTimeout(() => {
						notificationMsgShow = false
					}, 5000)
				}
			})
			// This will check if customer is already on call. If so then we will  don't call below function
			const isAlreadyOnCall = window.localStorage.getItem(`${props?.job?.id}-in-call`)
			if (((callDone && computerAudioEnabled) || customerCalledHimself) && isAlreadyOnCall != 'yes') {
				// console.log('JitsiContainer:::customerCalledHimself')
				window.localStorage.setItem('customerCallingHimSelf', false)
				const finalPhoneNumber = props?.job?.customer?.phoneNumber
				console.log('jitsiContainer::: callOnPhone Info:::', finalPhoneNumber)
				api.invite([
					{
						allowed: true,
						number: finalPhoneNumber,
						originalEntry: `${props.job.customer.phoneNumber}`,
						showCountryCodeReminder: false,
						type: 'phone',
					},
				])
				callDone = false
				// Here we are setting in localstorage that one time call is already done. If he/she want another call there are buttons available
				window.localStorage.setItem(`${props?.job?.id}-in-call`, 'yes')
			}

			api.on('errorOccurred', async (data) => {
				console.log('error occured in jitsi setup :::::: ', data)
			})

			api.on('participantJoined', async (info) => {
				console.log('JitsiContainer::: participantJoinedInfo', info)
				let joinedParticipentArr = []
				try {
					joinedParticipentArr = await api.getRoomsInfo()
					console.log('JitsiContainer::: joinedParticipentArr', joinedParticipentArr)
				} catch (error) {
					joinedParticipentArr = api.getParticipantsInfo()
					console.log('error while accessing participant',joinedParticipentArr)
				}
				// if somehow in above condition nothing works then we will again try to get getParticipantsInfo
				if (joinedParticipentArr.length >= 2) {
					console.log('while accessing participant  ',joinedParticipentArr)
					setAutomaticCallModal(false)
				}
				if (joinedParticipentArr.length <= 0) {
					joinedParticipentArr = api.getParticipantsInfo()
					console.log('JitsiContainer::: participantInfo inside Safetycheck condition', joinedParticipentArr)
				}
				console.log('JitsiContainer::: joinedParticipentArr', joinedParticipentArr)
				const checkDesktopAvailab = await checkDesktopAvailability(joinedParticipentArr)
				const checkPhoneAvailab = await checkPhoneAvailability(joinedParticipentArr)
				console.log('JitsiContainer::: checkPhoneAvailability', {checkDesktopAvailab,checkPhoneAvailab})
				let response = await getJobInformation(props.job.id, props?.user?.id )
				let job = response.data
				console.log('JitsiContainer::: job',job)

				const meetingStarted = !job.meetingStarted
				const paidMeetingAlertTime = !job?.paid_meeting_alert_time

				if (meetingStarted && !job.schedule_accepted) {
					props.setRequiredParticipantJoined(true)
					if (paidMeetingAlertTime) {
						updateJob(props.job.id, {
							dataToUpdate: { paid_meeting_alert_time: new Date() },
							cuId: props?.user?.id,
						})
					}
				}
				let participants = await getParticipantDetails(api, props.userType)
				console.log('participants ::: :::: in meeting',participants)
			
				if (meetingStarted && job.schedule_accepted && (participants.length >= 2 || job.schedule_five_min_popup)) {
					props.setRequiredParticipantJoined(true)
					if (paidMeetingAlertTime) {
						const dataToUpdate = {
							paid_meeting_alert_time: new Date(),
							schedule_five_min_popup: true,
						}
						updateJob(props.job.id, {
							dataToUpdate,
							cuId: props?.user?.id,
						})
					}
				}

				let lifeCycleTag = ''
				if (props.userType === 'technician') {
					lifeCycleTag = JobTags.TECHNICIAN_JITSI_START
				} else {
					lifeCycleTag = JobTags.CUSTOMER_JITSI_START
				}
				if (props.job.is_transferred && props.job.is_transferred == true) {
					if (props.userType === 'technician') {
						lifeCycleTag = JobTags.TECHNICIAN_JITSI_START_AFTER_TRANSFER
					} else {
						lifeCycleTag = JobTags.CUSTOMER_JITSI_START_AFTER_TRANSFER
					}
				}
				await JobCycleApi.create(lifeCycleTag, props.job.id)
				//mixpanel code
				mixpanel.identify(props.user.email)
				mixpanel.track(`${props.userType} - Joined `, { JobId: props.job.id })
				//mixpanel code
				setShowLoader(false)
			})

			window.participantInfo = setTimeout(async function () {
				if (api) {
					let partispantsArr = []
					partispantsArr = await getParticipantDetails(api, props.userType)
					// if somehow in above condition nothing works then we will again try to get getParticipantsInfo
					if (partispantsArr.length <= 0) {
						partispantsArr = api.getParticipantsInfo()
						console.log('JitsiContainer::: participantInfo inside Safetycheck condition 2', partispantsArr)
					}

					if (partispantsArr.length > 0) {
						if (props.userType == 'technician') {
							displayNametype = '(t)'
						} else {
							displayNametype = '(c)'
						}
						const valueToFind = props.user.firstName.trim() + ' ' + props.user.lastName.trim() + ' ' + displayNametype + ' (me)'
						// console.log('valuess original', valueToFind)

						let hasParticipants = false
						let hasParticipantsNoSpace = false
						for (var i = 0; i < partispantsArr.length; i++) {
							if (partispantsArr[i] && partispantsArr[i]['formattedDisplayName']) {
								let participantInfo = partispantsArr[i]['formattedDisplayName'].trim()
								let participantInfoNoSpace = partispantsArr[i]['formattedDisplayName'].replace(/\s/g, '')
								let valueToFindNoSpace = valueToFind.replace(/\s/g, '')
								console.log(
									'JitsiContainer::: Participant Name with trim is .' +
										participantInfo +
										'. and valueToFind Name is .' +
										valueToFind.trim() +
										'.',
								)
								console.log(
									'JitsiContainer::: Participant Name with no space is .' +
										participantInfoNoSpace +
										'. and valueToFind Name with no space is .' +
										valueToFindNoSpace +
										'.',
								)
								if (participantInfo === valueToFind.trim()) {
									hasParticipants = true
								}
								if (participantInfoNoSpace === valueToFindNoSpace) {
									hasParticipantsNoSpace = true
								}
							} else {
								console.log('JitsiContainer::: Participant do not have formatted display name ', partispantsArr)
							}
						}
						console.log(
							'JitsiContainer::: Trying to find ' +
								valueToFind +
								' from partispantArr and the result of hasParticipants is ' +
								hasParticipants.toString() +
								' and the result of hasParticipantsNoSpace is ' +
								hasParticipantsNoSpace.toString(),
						)
						if (hasParticipants || hasParticipantsNoSpace) {
							console.log('JitsiContainer:::loader hide::: Jitsi loaded for ' + props.userType + ' - ' + valueToFind)
							setShowLoader(false)
						} else {
							console.log(
								'JitsiContainer:::retryJitsi::: Because ' + props.userType + ' - ' + valueToFind + ' not found in partispantArr',
							)
							retryJitsi()
						}
					} else {
						console.log('JitsiContainer:::retryJitsi::: Because partispantArr is empty')
						retryJitsi()
					}
				} else {
					console.log('JitsiContainer:::retryJitsi::: Because jitsi api is not set.', api)
					retryJitsi()
				}
			}, 70000)
			// This function will start meeting recording
			startRecordingInterval = setInterval(() => {
				if(counter < 6){
					startRecording()
					counter++
				}
			}, 10000);
		} catch (error) {
			console.log('JitsiContainer:::retryJitsi:::type 4', error)
			//mixpanel codetoggleShareScreen
			mixpanel.identify(props.user.email)
			mixpanel.track(`Error on ${props.userType} side`, { Error: error })
			//mixpanel code
			retryJitsi()
		}
	}

	/**
	 * Reload page
	 * @params = ''
	 * @response : Will reload the page if jitsi not worked and retry max upto 3 times only.
	 * @author : Karan,Jagroop
	 */
	const retryJitsi = () => {
		try {
			let meetingRetires = window.localStorage.getItem(`${props.job.id}-meeting-retires`)
			// console.log('JitsiContainer:::retryJitsi:::reloading page initiate:', meetingRetires)
			if (Number(meetingRetires) < 2) {
				//mixpanel code
				mixpanel.identify(props.user.email)
				mixpanel.track(`${props.userType} - Jitsi retry called`, { JobId: props.job.id })
				//mixpanel code
				if (props.job && props.job.id && props.job.status !== 'Completed') {
					console.log('JitsiContainer:::retryJitsi:::reloading page done:', meetingRetires)
					window.localStorage.setItem(`${props.job.id}-meeting-retires`, Number(meetingRetires) + 1)
					window.location.href = `/meeting/${props.userType}/${props.job.id}`
				}
			} else {
				console.log('JitsiContainer:::retryJitsi:::max retires reached:', meetingRetires)
				//mixpanel code
				mixpanel.identify(props.user.email)
				mixpanel.track(`${props.userType} - Jitsi max retires reached `, { JobId: props.job.id })
				//mixpanel code
			}
		} catch (error) {
			console.log('JitsiContainer:::retryJitsi:::error', error)
		}
	}

	/**
	 * Stop jitsi screenshare
	 * @params = ''
	 * @response : Will stop the screenshare of jitsi
	 * @author : Karan
	 */
	const stopShareScreen = () => {
		//mixpanel code
		mixpanel.identify(props.user.email)
		mixpanel.track(`${props.userType} - Stop screen share`, { JobId: props.job.id, showStopButton: showStopButton })
		//mixpanel code
		if (api != null && showStopButton === true) {
			console.log('JitsiContainer:::stopShareScreen', showStopButton)
			api.executeCommand('toggleShareScreen')
			setshowStopButton(false)
		}
	}

	const checkParticipantInfo = async (timer) => {
		let partisipantsArrInitial
		if (api != null) {
			partisipantsArrInitial = await getParticipantDetails(api, props.userType)
		}

		if (
			!props?.job.meetingStarted &&
			props?.job.schedule_accepted &&
			!props.requiredParticipantJoined &&
			(partisipantsArrInitial.length >= 2 || props.job.schedule_five_min_popup)
		) {
			// console.log('JitsiContainer second')
			props.setRequiredParticipantJoined(true)
		}

		window.particintInfoCheckSetTimeOut = setTimeout(async () => {
			if (api != null) {
				let partisipantsArr = await getParticipantDetails(api, props.userType)
				console.log('JitsiContainer:::particintInfoCheckSetTimeOut', {
					participantAfterDisconnect: props.participantAfterDisconnect,
					partisipantsArr,
				})
				console.log('JitsiContainer:::particintInfoCheckSetTimeOut before', { partisipantsArrInitial })
				console.log('JitsiContainer:::particintInfoCheckSetTimeOut participant Count', {
					after: partisipantsArr.length,
					before: partisipantsArrInitial.length,
				})
				const participantsAfterLeft = partisipantsArrInitial.length
				const particiapantsListAfterTimeout = partisipantsArr.length
				// If timer is not started then we show popup with content regarding :"connect to user"
				if (
					!props?.job?.meeting_start_time &&
					participantsAfterLeft < 2 &&
					particiapantsListAfterTimeout < 2 &&
					!props.showMicPermissionModal
				) {
					setAutomaticCallModal(true)
					const userType = props.userType == 'customer' ? 'technician' : 'customer'
					setDisconnectedUserType(userType)
					// openNotificationWithIcon('success', "You've been reconnected! Enjoy your meeting")
				}
				// If paid time already started then we will show different content : "reconnecting user"
				if (participantsAfterLeft == particiapantsListAfterTimeout && particiapantsListAfterTimeout < 2 && props?.job?.meeting_start_time) {
					setAutomaticCallModal(true)
					const userType = props.userType == 'customer' ? 'technician' : 'customer'
					setDisconnectedUserType(userType)
					// openNotificationWithIcon('success', "You've been reconnected! Enjoy your meeting")
					props.socket.emit('participant-left', { JobId: props.job.id, disconnected: true, userType: userType, showPopup: true })
				}
			}
		}, timer)
	}

	/**
	 * Start jitsi recording
	 * @params = ''
	 * @response : Will start the jitsi recording copied form V1 of this project
	 * @author : Manibha
	 */
	const startRecording = () => {
		window.startRecordingTimeOut = setTimeout(() => {
			if (api != null) {
				console.log('JitsiContainer:::startRecording')
				api.executeCommand('startRecording', {
					mode: 'file',
				})
			}
		// }, Number(props.internetConnectionStatus[props.userType]) > 8 ? 10000 : 13000)
		},10000)
	}

	return (
		<>
			{!isMobile && oneapi && props.userType === 'customer' && props.job && (
				<SharingYourScreenModal job={props.job} setOpenShareScreenModal={setOpenShareScreenModal} openShareScreenModal={openShareScreenModal} jitsiApi={api} />
			) }
			{props.showMicPermissionModal && props.job && api && props.userType && props.job.callStartType === 'ComputerAudio' && !props.phoneStatusAvailability && (
				<MicPermissionModal
					showMicPermissionModal={props.showMicPermissionModal}
					setShowMicPermissionModal={props.setShowMicPermissionModal}
					job={props.job}
					jitsiApi={api}
					userType={props.userType}
				/>
			)}

			{openAutomaticCallModal && props.job && api && !props.jitsiSessionEnd && (
				<AutomaticCallConnectModal
					openAutomaticCallModal={openAutomaticCallModal}
					job={props.job}
					setAutomaticCallModal={setAutomaticCallModal}
					disconnectedUserType={disconnectedUserType}
					jitsiApi={api}
					userType={props?.userType}
				/>
			)}

			{props.showRemoteAccessModal && props.job && api && props.userType && (
				<AllowRemoteAccessModal
					showRemoteAccessModal={props.showRemoteAccessModal}
					setShowRemoteAccessModal={props.setShowRemoteAccessModal}
				/>
			)}

			<Col xs="12" className="jitsi-screen-outer text-center mt-4">
				{showLoader && (
					<div className="session_loading session_loading_preloader">
						<MeetingOnboarding
							user={props.user}
							userType={props.userType}
							connectionStatus={connectionStatus}
							job={props.job}
							setStartChat={props.setStartChat}
						/>
					</div>
				)}

				{props.jitsiSessionEnd && (
					<div className="no-meeting-loaded">
						<div className="meeting-area text-center">
							<h2 className="mb-3">Ending meeting, please wait.....</h2>
						</div>
					</div>
				)}
				{(props.requiredParticipantJoined || props.job?.meeting_pause) && oneapi && !props.hasMeetingStarted && (
					<StartPaidTimerAlert
						userType={props?.userType}
						job={props.job}
						totalPaidJobs={props.totalPaidJobs}
						socket={props.socket}
						api={oneapi}
						user={props.user}
						setRequiredParticipantJoined={props.setRequiredParticipantJoined}
					/>
				)}
				{!props.jitsiSessionEnd && <div id={props.jitsiContainerId} />}
			</Col>
		</>
	)
}

export default JitsiContainer
