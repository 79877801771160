import React from 'react'
import { JitsiMeetProvider } from './jitsiMeetContext'
import { NotificationProvider } from './notificationContext'

function AppProviders({ children }) {
	return (
		<JitsiMeetProvider>
			{' '}
			<NotificationProvider> {children} </NotificationProvider>{' '}
		</JitsiMeetProvider>
	)
}

export default AppProviders
